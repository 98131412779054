import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";

import useError from "@hooks/useError";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import useLoading from "@hooks/useLoading";

import Background from "../Shared/Background";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";

import TextField from "@components/Global/Custom/TextField";

import { adjective as proAdjective } from "@language/provider";

import Fingerprint from "@src/libs/Fingerprint";

const Contact = observer(({ provider, secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { contactStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	const [LoadingTemplate, onLoading] = useLoading({ _id: "LeadFunnelContact" });

	const emailRef = useRef();

	const fingerprint = new Fingerprint(store.accountId);

	const nextHandler = () => {
		try {
			fingerprint.initialize(emailRef.current.value);
			store.next(emailRef);
		} catch (err) {
			onError(err);
		}
	};
	const prevHandler = () => store.prev();
	const onChangeHandler = (e) => store.update(e.target.value);

	useEffect(() => {
		store
			.init()
			.then(() => onLoading(false))
			.catch(onError);
		return () => onLoading(true);
	}, []);

	return (
		<>
			<Header progress={store.progress} />

			<DialogContent className={classes.dialogContent}>
				<Background nextHandler={nextHandler}>
					<LoadingTemplate>
						<Grid container>
							<Grid item xs={false} sm={2} />
							<Grid item xs={12} sm={8}>
								<div
									className={
										secondary
											? classes.questionHeaderBox2
											: classes.questionHeaderBox
									}
								>
									<div
										className={
											secondary
												? classes.questionHeader2
												: classes.questionHeader
										}
									>
										{provider ? (
											<>
												{`Where should we send the ${proAdjective.singular}'s response?`}
											</>
										) : (
											<>{`Where should we send your matches?`}</>
										)}
									</div>
									<div
										className={
											secondary
												? classes.questionSubHeader2
												: classes.questionSubHeader
										}
									>
										{`We don't share your email with ${proAdjective.plural}.`}
									</div>
								</div>
								<div>
									<TextField
										inputRef={emailRef}
										type={"email"}
										value={store.email || ""}
										placeholder="Email address"
										onChange={onChangeHandler}
										variant={"outlined"}
										fullWidth={true}
									/>
								</div>
							</Grid>
							<Grid item xs={false} sm={2} />
						</Grid>
					</LoadingTemplate>
				</Background>
			</DialogContent>

			<ErrorTemplate />
			<Footer nextHandler={nextHandler} prevHandler={prevHandler} />
		</>
	);
});

export default Contact;
