import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import useStores from "@hooks/useStores";
import useLoading from "@hooks/useLoading";
import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";
import shortid from "shortid";
import LiaisonProCheckbox from "./LiaisonProCheckbox";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Link from "@material-ui/core/Link";
import { adjective as proAdjective } from "@language/provider";
import DialogContent from "@material-ui/core/DialogContent";
import LiaisonFooter from "./LiaisonFooter";

const Liaison = observer(({ onError }) => {
	const classes = useStyles("LeadFunnel");
	const { liaisonStore: store, moreProsStore } = useStores();
	const [LoadingTemplate, onLoading] = useLoading({ _id: "MoreProsLiaison" });

	useEffect(() => {
		store
			.init()
			.then(() => {
				onLoading(false);
			})
			.catch(onError);
		return () => {};
	}, []);

	const nextHandler = () => moreProsStore.next().catch(onError);
	const notNowHandler = () => moreProsStore.setShowAreYouSure(true);
	const selectOnClickHandler = () => {
		if (store.isAllSelected) {
			store.deselectAll();
		} else {
			store.selectAll();
		}
	};

	return (
		<>
			<DialogContent className={classes.dialogContentLiaison}>
				<Box pl={{ xs: 0, sm: 8 }} pr={{ xs: 0, sm: 8 }}>
					{/* <Box fontWeight={700} mb={2} color={"primary.main"} fontSize={12}>
						Recommended
					</Box> */}
					<div>
						<Box
							className={classes.questionHeader}
							mb={2}
							textAlign={"left !important"}
						>
							{`Share your request with a few ${
								store.ogProvider ? "more" : ""
							} ${proAdjective.plural}.`}
						</Box>
						<Box lineHeight={1.6}>
							{`To help you compare options, reach out to a few more ${proAdjective.plural}. These ${proAdjective.plural} match your request details perfectly.`}
						</Box>
					</div>

					<Box mt={4} mb={4}>
						<List>
							<LoadingTemplate>
								<Box mb={3} fontWeight={600}>
									<Link color="primary" onClick={selectOnClickHandler}>
										{store.isAllSelected ? "Unselect all" : "Select all"}
									</Link>
								</Box>

								{store.matches.map((match) => (
									<LiaisonProCheckbox {...match} key={shortid.generate()} />
								))}
							</LoadingTemplate>
						</List>
					</Box>
				</Box>
			</DialogContent>
			<LiaisonFooter nextHandler={nextHandler} notNowHandler={notNowHandler} />
		</>
	);
});

Liaison.propTypes = {};
export default Liaison;
