import React, { useEffect } from "react";
import shortid from "shortid";

import useError from "@hooks/useError";
import useStores from "@hooks/useStores";

import ModalXButton from "@components/Global/Custom/ModalXButton";
import Header from "@components/Main/Shared/LeadFunnel/Steps/Shared/Header";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Fade from "@material-ui/core/Fade";
import useStyles from "@hooks/useStyles";
import Divider from "@material-ui/core/Divider";

import { adjective as proAdjective } from "@language/provider";

const Summary = ({ provider, secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { summaryStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();

	useEffect(() => {
		store.run().catch(onError);
	});

	return (
		<React.Fragment>
			<Header />
			<DialogContent className={classes.dialogContent}>
				<Grid container style={{ height: "100%" }}>
					<Grid item xs={false} sm={1} />
					<Grid item xs={12} sm={10}>
						<Box
							component={"div"}
							height={"100%"}
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"center"}
						>
							{provider ? (
								<Box mb={2}>
									<Avatar
										variant={"rounded"}
										src={provider.image.value}
										className={classes.summaryAvatar}
									/>
								</Box>
							) : (
								<AvatarGroup max={3}>
									<Avatar
										src={
											"https://res.cloudinary.com/liaison-inc/image/upload/s--q0hzbLn2--/c_fill,f_auto,h_400,w_400/v1/tutors/user-media/stage/approved/provider/61f7521357e54c628e6e0ac9/profile/KPDEJEXg/61f7521357e54c628e6e0ac9_profile.jpg"
										}
										className={classes.summaryAvatar}
										imgProps={{ loading: "lazy", decoding: "async" }}
									/>
									<Avatar
										src={
											"https://res.cloudinary.com/liaison-inc/image/upload/s--MGWsm3Qe--/c_fill,f_auto,h_400,w_400/v1/tutors/user-media/prod/approved/provider/5dc27b38fc286d3b9768e2ba/profile/T1OrhYrxB/IMG_1676%20(1).jpeg"
										}
										className={classes.summaryAvatar}
										imgProps={{ loading: "lazy", decoding: "async" }}
									/>
									<Avatar
										src={
											"https://res.cloudinary.com/liaison-inc/image/upload/s--tknNuC38--/c_fill,f_auto,h_400,w_400/v1/tutors/user-media/stage/approved/provider/5ff74214366f653c43efba90/profile/3ZRfjEx4/5ff74214366f653c43efba90_profile.jpg"
										}
										className={classes.summaryAvatar}
										imgProps={{ loading: "lazy", decoding: "async" }}
									/>
								</AvatarGroup>
							)}
							<div className={classes.questionHeaderBox}>
								<Box className={classes.questionHeader} mb={5}>
									{provider ? (
										<>
											Saving and sending your{" "}
											<Box display={"block"}>project details...</Box>
										</>
									) : (
										<>
											Matching your answers{" "}
											<Box display={"block"}>with {proAdjective.plural}...</Box>
										</>
									)}
								</Box>

								{store.answers.map(({ values }, index) => (
									<Fade
										key={shortid.generate()}
										in={true}
										timeout={{
											enter: store.delay * index,
										}}
									>
										<Box
											component={"div"}
											mb={2}
											lineHeight={1.6}
											textAlign={"center"}
											color={"grey.700"}
										>
											{values.join(", ")}
										</Box>
									</Fade>
								))}
							</div>
						</Box>
					</Grid>
					<Grid item xs={false} sm={1} />
				</Grid>

				<ErrorTemplate />
			</DialogContent>
		</React.Fragment>
	);
};

export default Summary;
