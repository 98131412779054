import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

const Background = ({ focus, className, children }) => {
	return (
		<Box tabIndex={"0"} className={className} overflow={"hidden"}>
			{children}
		</Box>
	);
};

Background.defaultProps = {
	className: "",
	focus: true,
	nextHandler: () => {},
};

Background.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default Background;
