import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import useError from "@hooks/useError";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import PhoneFooter from "./PhoneFooter";

import { adjective as proAdjective } from "@language/provider";

import Background from "@components/Main/Shared/LeadFunnel/Steps/Shared/Background";
import Box from "@material-ui/core/Box";
import Confetti from "react-dom-confetti";
import DialogContent from "@material-ui/core/DialogContent";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import ForumOutlinedIcon from "@material-ui/icons/ForumOutlined";
import TextField from "@components/Global/Custom/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import CloudDoneOutlinedIcon from "@material-ui/icons/CloudDoneOutlined";

const config = {
	angle: "270",
	spread: "200",
	startVelocity: 18,
	elementCount: 70,
	dragFriction: 0.12,
	duration: 3000,
	stagger: 2,
	width: "10px",
	height: "10px",
	perspective: "500px",
	colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const Phone = observer(({ service, secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { phoneStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();

	useEffect(() => {
		store.init().catch(onError);
	}, []);

	const skipHandler = () => store.skip();
	const nextHandler = () => store.next();

	const phoneOnChangeHandler = (e) => store.updatePhone(e.target.value);
	const textNotificationOnChangeHandler = (e) =>
		store.updateTextNotification(e.target.checked);

	const [explode, setExplode] = useState(false);
	useEffect(() => {
		setExplode(true);
	}, []);

	setTimeout(() => setExplode(false), 100);

	// ga("send", {
	// 	hitType: "event",
	// 	eventCategory: "Clients",
	// 	eventAction: "lead",
	// });

	// gtag("event", "submit_a_lead", {
	// 	event_name: "lead",
	// 	event_action: "lead",
	// 	event_category: "Clients",
	// });

	return (
		<>
			<DialogContent className={classes.dialogContent}>
				<Background nextHandler={nextHandler}>
					<Box position={"absolute"} right={"50%"} top={0} zIndex={1}>
						<Confetti active={explode} config={config} />
					</Box>

					{store.lead.picks.length ? (
						<Box px={{ xs: 1, sm: 8 }} pt={4} pb={3}>
							{/* <Box fontWeight={700} mb={2} color={"success.main"}>
								<CheckCircleIcon fontSize="large" />
							</Box> */}
							<div>
								<Box className={classes.questionHeader} mb={6}>
									<mark
										style={{
											backgroundColor: "rgba(192, 255, 226, 1)",
											padding: "2px 6px",
											borderRadius: 8,
										}}
									>
										Done!
									</mark>
									{` You sent your request to ${store.lead.picks.length} ${
										store.lead.picks.length > 1
											? proAdjective.plural
											: proAdjective.singular
									}.`}
								</Box>

								{/*TODO: fill in pro count and avatar alt*/}
								<Box
									mb={8}
									display={"flex"}
									alignItems={"center"}
									justifyContent={"center"}
								>
									{store.selectedPros.map((pick) => (
										<Box mr={2} position={"relative"}>
											<Avatar
												variant={"circle"}
												alt="xxx"
												src={pick.image?.value}
												className={classes.doneAvatar}
											/>
											<Box
												position={"absolute"}
												top={0}
												right={0}
												display={"flex"}
												bgcolor={"#FFFFFF"}
												borderRadius={"50%"}
												color={"success.main"}
											>
												<CheckCircleIcon />
											</Box>
										</Box>
									))}
								</Box>
							</div>
							<Paper elevation={3}>
								<Box p={{ xs: 4, sm: 6 }}>
									<Box
										fontSize={{ xs: 18, sm: 20 }}
										fontWeight={700}
										mb={6}
										color={"grey.700"}
									>
										Next steps
									</Box>
									<Box display={"flex"} alignItems={"flex-start"}>
										<SmsOutlinedIcon style={{ fontSize: 32 }} />
										<Box ml={4}>
											<Box fontWeight={700} mb={1}>
												{`Expect to hear back soon.`}
											</Box>
											<Box
												fontSize={14}
												lineHeight={1.6}
												color={"grey.700"}
												mb={2}
											>
												{`${proAdjective.plural.ucFirst()} usually respond within 1 to 4 hours. `}
												{/* Enter your phone number to get texted instantly when they
											do. */}
											</Box>
											{/* <div>
											<TextField
												size={"small"}
												groupId={"signup-form"}
												type={"tel"}
												value={store.phone || ""}
												onChange={phoneOnChangeHandler}
												variant="outlined"
												placeholder={"(555) 555-5555"}
												id="tel"
												name="tel"
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<PhoneIcon />
														</InputAdornment>
													),
												}}
											/>
										</div> */}
										</Box>
									</Box>
									<Box display={"flex"} alignItems={"flex-start"} mt={8}>
										<PersonAddOutlinedIcon style={{ fontSize: 32 }} />
										<Box ml={4}>
											<Box fontWeight={700} mb={1}>
												{`Contact a few more ${proAdjective.plural}.`}
											</Box>
											<Box fontSize={14} lineHeight={1.6} color={"grey.700"}>
												{`Contacting more ${proAdjective.plural} helps you compare and hire the best.`}
											</Box>
										</Box>
									</Box>
									<Box display={"flex"} alignItems={"flex-start"} mt={8} mb={6}>
										<CloudDoneOutlinedIcon style={{ fontSize: 32 }} />
										<Box ml={4}>
											<Box fontWeight={700} mb={1}>
												Your request details were saved.
											</Box>
											<Box fontSize={14} lineHeight={1.6} color={"grey.700"}>
												{`You can reach out to additional ${proAdjective.plural} with a single click on the next page.`}
											</Box>
										</Box>
									</Box>
								</Box>
							</Paper>
						</Box>
					) : (
						<Box px={{ xs: 1, sm: 8 }} pt={4} pb={3}>
							{/* <Box fontWeight={700} mb={2} color={"success.main"}>
								<CheckCircleIcon fontSize="large" />
							</Box> */}
							<div>
								<Box className={classes.questionHeader} mb={6}>
									<mark
										style={{
											backgroundColor: "rgba(192, 255, 226, 1)",
											padding: "2px 6px",
											borderRadius: 8,
										}}
									>
										Nice!
									</mark>
									{` We have matching ${proAdjective.plural} in your area.`}
								</Box>

								{/*TODO: fill in pro count and avatar alt*/}
								<Box mb={4} display={"flex"} alignItems={"center"}>
									{store.selectedPros.map((pick) => (
										<Box mr={2} position={"relative"}>
											<Avatar
												variant={"circle"}
												alt="xxx"
												src={pick.image?.value}
												className={classes.doneAvatar}
											/>
											<Box
												position={"absolute"}
												top={0}
												right={0}
												display={"flex"}
												bgcolor={"#FFFFFF"}
												borderRadius={"50%"}
												color={"success.main"}
											>
												<CheckCircleIcon />
											</Box>
										</Box>
									))}
								</Box>
							</div>
							<Paper elevation={3}>
								<Box p={{ xs: 4, sm: 6 }}>
									<Box
										fontSize={{ xs: 18, sm: 20 }}
										fontWeight={700}
										mb={6}
										color={"grey.700"}
									>
										Next steps
									</Box>

									<Box display={"flex"} alignItems={"flex-start"} mt={8}>
										<PersonAddOutlinedIcon style={{ fontSize: 32 }} />
										<Box ml={4}>
											<Box fontWeight={700} mb={1}>
												{`Share your request with a few ${proAdjective.plural}.`}
											</Box>
											<Box fontSize={14} lineHeight={1.6} color={"grey.700"}>
												{`We recommend contacting 2 to 3 ${proAdjective.plural} so that you can compare prices and availability.`}
											</Box>
										</Box>
									</Box>
									<Box display={"flex"} alignItems={"flex-start"} mt={8} mb={6}>
										<CloudDoneOutlinedIcon style={{ fontSize: 32 }} />
										<Box ml={4}>
											<Box fontWeight={700} mb={1}>
												Your request details were saved.
											</Box>
											<Box fontSize={14} lineHeight={1.6} color={"grey.700"}>
												{`You can reach out to additional ${proAdjective.plural} with a single click on the next page.`}
											</Box>
										</Box>
									</Box>
									<Box display={"flex"} alignItems={"flex-start"}>
										<SmsOutlinedIcon style={{ fontSize: 32 }} />
										<Box ml={4}>
											<Box fontWeight={700} mb={1}>
												{`Expect to hear back soon.`}
											</Box>
											<Box
												fontSize={14}
												lineHeight={1.6}
												color={"grey.700"}
												mb={2}
											>
												{`We'll send your request to the best matches. Interested ${proAdjective.plural} usually respond within 1 to 4 hours. `}
												{/* Enter your phone number to get texted instantly when they
											do. */}
											</Box>
										</Box>
									</Box>
								</Box>
							</Paper>
						</Box>
					)}
				</Background>
			</DialogContent>

			<ErrorTemplate />

			{/*<Footer nextHandler={nextHandler}/>*/}
			{store.lead.picks.length ? (
				<PhoneFooter nextHandler={nextHandler} skipHandler={skipHandler} />
			) : (
				<PhoneFooter
					nextHandler={nextHandler}
					skipHandler={skipHandler}
					buttonText={`View matching ${proAdjective.plural}`}
				/>
			)}
		</>
	);
});

export default Phone;
