import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";

import useError from "@hooks/useError";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import useLoading from "@hooks/useLoading";

import Background from "../Shared/Background";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@components/Global/Custom/TextField";

const Name = observer(({ secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { nameStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	const [LoadingTemplate, onLoading] = useLoading({ _id: "LeadFunnelName" });

	const nameRef = useRef();

	const nextHandler = async () => {
		try {
			await store.next(nameRef);
		} catch (err) {
			onError(err);
		}
	};
	const prevHandler = () => store.prev();
	const onChangeHandler = (e) => store.update(e.target.value);

	useEffect(() => {
		store
			.init()
			.then(() => onLoading(false))
			.catch(onError);
		return () => onLoading(true);
	}, []);

	return (
		<>
			<Header progress={store.progress} />
			<DialogContent className={classes.dialogContent}>
				<Background
					nextHandler={nextHandler}
					className={`${classes.overflowShow} ${classes.height100}`}
				>
					<LoadingTemplate>
						<Grid container style={{ height: "100%" }}>
							<Grid item xs={false} sm={2} />
							<Grid item xs={12} sm={8}>
								<div
									className={
										secondary
											? classes.questionHeaderBox2
											: classes.questionHeaderBox
									}
								>
									<div
										className={
											secondary
												? classes.questionHeader2
												: classes.questionHeader
										}
									>
										Please enter your full name.
									</div>
								</div>
								<Box
									display={"flex"}
									justifyContent="space-between"
									flexDirection={"column"}
									height="calc(100% - 110px)"
									minHeight={secondary ? 300 : 350}
								>
									<div>
										<TextField
											inputRef={nameRef}
											type="name"
											value={store.name || ""}
											placeholder="First and last name"
											onChange={onChangeHandler}
											variant={"outlined"}
											fullWidth={true}
										/>
									</div>
									<Box fontSize={14} lineHeight={1.5}>
										By clicking Next you agree to the{" "}
										<Link href="/terms-of-service" target="_blank">
											Terms of Service
										</Link>{" "}
										and{" "}
										<Link href="/privacy-policy" target="_blank">
											Privacy Policy
										</Link>
									</Box>
								</Box>
							</Grid>
							<Grid item xs={false} sm={2} />
						</Grid>
					</LoadingTemplate>
				</Background>
			</DialogContent>
			<ErrorTemplate />
			<Footer nextHandler={nextHandler} prevHandler={prevHandler} />
		</>
	);
});

export default Name;
