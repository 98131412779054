import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import Button from "@custom/Button";
import useStyles from "@hooks/useStyles";

const { SITE_NAME } = process.env;

const LiaisonFooter = observer(({ notNowHandler, nextHandler }) => {
	const classes = useStyles("LeadFunnel");
	const { liaisonStore: store } = useStores();

	return (
		<React.Fragment>
			<DialogActions className={classes.dialogActions}>
				<Box
					display={"flex"}
					minWidth={"50%"}
					flexDirection={"row-reverse"}
					className={classes.dialogActionsBox}
				>
					<Box flexGrow={1}>
						{store.picks.length === 0 ? (
							<Button
								disableElevation
								fullWidth
								size={"large"}
								variant={"outlined"}
								onClick={notNowHandler}
							>
								Skip for now
							</Button>
						) : (
							<Button
								disableElevation
								fullWidth
								size={"large"}
								variant={"contained"}
								color="primary"
								onClick={nextHandler}
							>
								{SITE_NAME === "homeguide" ? (
									<>Get free estimates</>
								) : (
									<>Check availability</>
								)}
							</Button>
						)}
					</Box>
				</Box>
			</DialogActions>
		</React.Fragment>
	);
});

LiaisonFooter.propTypes = {
	nextHandler: PropTypes.func.isRequired,
	notNowHandler: PropTypes.func.isRequired,
};

export default LiaisonFooter;
