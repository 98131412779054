import React from "react";
import useStores from "@hooks/useStores";

import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";

import CloseIcon from "@material-ui/icons/Close";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DialogContent from "@material-ui/core/DialogContent";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import useStyles from "@hooks/useStyles";
import shortid from "shortid";

import { adjective as proAdjective } from "@language/provider";
const { SITE_NAME } = process.env;

const Cancel = ({ show = false, setShowCancelModal }) => {
	const classes = useStyles("LeadFunnel");
	const {
		moreProsStore: store,
		leadFunnelModalStore,
		leadFunnelStore,
	} = useStores();
	const continueRequestHandler = () => setShowCancelModal(false);
	const cancelRequestHandler = () => leadFunnelModalStore.onClose();
	const progress = leadFunnelStore.Control?.progress;

	return (
		<React.Fragment>
			<Dialog
				fullWidth={true}
				maxWidth={"xs"}
				open={show}
				//onClose={leadFunnelModalStore.onClose}
				aria-labelledby="Do you want to continue?"
				aria-describedby="Do you want to continue?"
			>
				<DialogTitle disableTypography></DialogTitle>
				<DialogContent>
					<Box px={{ xs: 2, sm: 4 }} mb={8}>
						<Box mb={8}>
							<Box
								fontSize={{ xs: 22, sm: 24 }}
								fontWeight={700}
								lineHeight={1.3}
								mb={3}
							>
								{/* {`Don't stop now. You're ${progress}% done with your request.`} */}
								{`You're almost done`}
							</Box>
							<Box>{`If you cancel now, you'll lose all your progress.`}</Box>
						</Box>

						<Box mb={10}>
							<Box mb={3}>
								<Button
									onClick={continueRequestHandler}
									disableElevation
									size={"large"}
									variant={"contained"}
									color="primary"
									fullWidth
									endIcon={<ArrowForwardIcon />}
								>
									Continue request
								</Button>
							</Box>
							<div>
								<Button
									style={{ color: "#D50000" }}
									onClick={cancelRequestHandler}
									size={"large"}
									variant={"outlined"}
									fullWidth
									endIcon={<CloseIcon />}
								>
									Cancel request
								</Button>
							</div>
						</Box>
						{SITE_NAME === "off" && (
							<Box mb={8}>
								<Box
									fontSize={{ xs: 18, sm: 20 }}
									fontWeight={700}
									lineHeight={1.3}
									mb={3}
								>
									{/* {`Don't stop now. You're ${progress}% done with your request.`} */}
									{`Want help over the phone?`}
								</Box>
								<Box
									mb={8}
									lineHeight={1.5}
								>{`Give us a call and we can help match you with the right ${proAdjective.singular} for the job!`}</Box>
								<div>
									<Button
										href="tel:(888) 519-1353"
										size={"large"}
										variant={"outlined"}
										fullWidth
									>
										Call &nbsp;{" "}
										<Box component={"span"} letterSpacing={0.6}>
											(888) 519-1353
										</Box>
									</Button>
								</div>
							</Box>
						)}
					</Box>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};

export default Cancel;
