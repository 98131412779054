import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";

import useError from "@hooks/useError";
import useStores from "@hooks/useStores";
import useLoading from "@hooks/useLoading";
import useStyles from "@hooks/useStyles";

import Background from "../Shared/Background";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

import TextField from "@components/Global/Custom/TextField";

import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { getParameterByName } from "@utils/Browser";

import { verb as proVerb } from "@language/provider";

const Zipcode = observer(({ service, secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { zipcodeStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	const [LoadingTemplate, onLoading] = useLoading({ _id: "LeadFunnelZipcode" });

	const zipcodeRef = useRef();

	const nextHandler = () => store.next(zipcodeRef).catch(onError);
	const prevHandler = () => store.prev();
	const onChangeHandler = (e) => store.update(e.target.value);

	useEffect(() => {
		store
			.init()
			.then(() => onLoading(false))
			.catch(onError);
		return () => onLoading(true);
	}, []);

	let headerText = "Please confirm your zip code.";
	let subHeaderText;
	if (getParameterByName("showZip") === "1") {
		headerText = `Compare quotes from top-rated ${store.service.plural}.`;
		subHeaderText = "Enter the location of your project.";
	}

	return (
		<>
			<Header progress={store.progress} />

			<DialogContent className={classes.dialogContent}>
				<Background nextHandler={nextHandler}>
					<LoadingTemplate>
						<div
							className={
								secondary
									? classes.questionHeaderBox2
									: classes.questionHeaderBox
							}
						>
							<div
								className={
									secondary ? classes.questionHeader2 : classes.questionHeader
								}
							>
								{headerText}
								{/* Please confirm where you need the{" "}
										{service.singular || `${service.name} ${proVerb.singular}`}. */}
							</div>
							{subHeaderText && (
								<div
									className={
										secondary
											? classes.questionSubHeader2
											: classes.questionSubHeader
									}
								>
									{subHeaderText}
								</div>
							)}
						</div>
						<Grid container>
							<Grid item xs={false} sm={2} />
							<Grid item xs={12} sm={8}>
								<div>
									<TextField
										inputRef={zipcodeRef}
										fullWidth={true}
										type={"zipcode"}
										value={store.zipcode}
										placeholder="Zip code"
										onChange={onChangeHandler}
										variant={"outlined"}
									/>
								</div>
							</Grid>
							<Grid item xs={false} sm={2} />
						</Grid>
					</LoadingTemplate>
				</Background>
			</DialogContent>

			<ErrorTemplate />
			<Footer nextHandler={nextHandler} prevHandler={prevHandler} />
		</>
	);
});

export default Zipcode;
