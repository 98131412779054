import React, { useEffect, useState } from "react";
import ProTypes from "prop-types";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";

import Background from "../Shared/Background";
import Header from "../Shared/Header";

import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Footer from "@components/Main/Shared/LeadFunnel/Steps/Shared/Footer";
import Grid from "@material-ui/core/Grid";

import { adjective as proAdjective } from "@language/provider";

const { SITE_NAME } = process.env;

const Intro = observer(({ secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { introStore: store } = useStores();
	const nextHandler = () => store.next();

	return (
		<React.Fragment>
			<Header />

			<DialogContent>
				<Background nextHandler={nextHandler} className={classes.dialogContent}>
					<Grid container style={{ height: "100%" }}>
						<Grid item xs={false} sm={1} />
						<Grid item xs={12} sm={10}>
							<Box
								component={"div"}
								height={"100%"}
								display={"flex"}
								flexDirection={"column"}
								justifyContent={"center"}
								alignItems={"center"}
							>
								{store.image ? (
									<Box mb={2}>
										<Avatar
											variant={"circle"}
											alt={store.company_name}
											src={store.image}
											className={classes.introAvatar}
											imgProps={{ loading: "lazy", decoding: "async" }}
										/>
									</Box>
								) : (
									<Box mb={2}>
										<AvatarGroup max={3}>
											<Avatar
												src={
													"https://res.cloudinary.com/liaison-inc/image/upload/s--q0hzbLn2--/c_fill,f_auto,h_400,w_400/v1/tutors/user-media/stage/approved/provider/61f7521357e54c628e6e0ac9/profile/KPDEJEXg/61f7521357e54c628e6e0ac9_profile.jpg"
												}
												className={classes.summaryAvatar}
												imgProps={{ loading: "lazy", decoding: "async" }}
											/>
											<Avatar
												src={
													"https://res.cloudinary.com/liaison-inc/image/upload/s--MGWsm3Qe--/c_fill,f_auto,h_400,w_400/v1/tutors/user-media/prod/approved/provider/5dc27b38fc286d3b9768e2ba/profile/T1OrhYrxB/IMG_1676%20(1).jpeg"
												}
												className={classes.summaryAvatar}
												imgProps={{ loading: "lazy", decoding: "async" }}
											/>
											<Avatar
												src={
													"https://res.cloudinary.com/liaison-inc/image/upload/s--tknNuC38--/c_fill,f_auto,h_400,w_400/v1/tutors/user-media/stage/approved/provider/5ff74214366f653c43efba90/profile/3ZRfjEx4/5ff74214366f653c43efba90_profile.jpg"
												}
												className={classes.summaryAvatar}
												imgProps={{ loading: "lazy", decoding: "async" }}
											/>
										</AvatarGroup>
									</Box>
								)}
								<div className={classes.questionHeaderBox}>
									{store.provider ? (
										<div className={classes.questionHeader}>
											{(store.type === "requested_availability") === true ? (
												<>{`To check this ${proAdjective.singular}'s availability, we need some details about your request.`}</>
											) : (store.type === "requested_booking") === true ? (
												<>{`To book this ${proAdjective.singular}, we need some details about your request.`}</>
											) : (store.type === "requested_call") === true ? (
												<>{`To request a call with this ${proAdjective.singular}, we need some details about your request.`}</>
											) : (store.type === "requested_message") === true ? (
												<>{`To message this ${proAdjective.singular}, we need some details about your request.`}</>
											) : (store.type === "requested_quote") === true ? (
												<>{`Share details about your request for a more accurate cost estimate.`}</>
											) : (
												<>{`Post a project and let interested ${proAdjective.plural} contact you.`}</>
											)}
										</div>
									) : (
										<div className={classes.questionHeader}>
											{["tutors"].includes(SITE_NAME) ? (
												<>
													{`In 2 minutes get pricing and find the right tutor for you.`}
												</>
											) : (
												<>
													{`Post a project and let interested ${proAdjective.plural} contact you.`}
												</>
											)}
										</div>
									)}
									<div className={classes.questionSubHeader}>
										{store.provider ? (
											<>
												{`A little info helps the ${proAdjective.singular} understand your request.`}
											</>
										) : (
											<>
												{`We're asking you some questions so we can match you with the right ${proAdjective.plural}.`}
											</>
										)}
									</div>
								</div>
							</Box>
						</Grid>
						<Grid item xs={false} sm={1} />
					</Grid>
				</Background>
			</DialogContent>

			<Footer nextHandler={nextHandler} />
		</React.Fragment>
	);
});

Intro.propTypes = {
	service: ProTypes.shape({
		phrase: ProTypes.string.isRequired,
	}).isRequired,
};

export default Intro;
