import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import shortid from "shortid";

import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";
import useStores from "@hooks/useStores";
import useLoading from "@hooks/useLoading";

import Background from "../Shared/Background";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";

import FormControl from "@material-ui/core/FormControl";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import { adjective as proAdjective } from "@language/provider";

const ChooseService = observer(({ secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { chooseServiceStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	const [LoadingTemplate, onLoading] = useLoading({
		_id: "LeadFunnelChooseService",
	});

	const nextHandler = async () => {
		try {
			const serviceId = serviceChoiceRefs.find((ref) => ref.current.checked)
				.current.value;
			await store.updateService(serviceId);
		} catch (err) {
			onError(err);
		}
	};
	const prevHandler = () => store.prev();
	const onKeyPress = (e) => {
		e.key === "Enter" && nextHandler();
	};

	const serviceChoiceRefs = store.affinities.map(() => useRef());

	useEffect(() => {
		onLoading(true);
		store
			.init()
			.then(() => onLoading(false))
			.catch((err) => onError(err));
	}, []);

	return (
		<React.Fragment>
			<Header progress={store.progress} />
			<DialogContent className={classes.dialogContent}>
				<LoadingTemplate>
					<Background nextHandler={nextHandler}>
						<Grid container>
							<Grid item xs={false} sm={2} />
							<Grid item xs={12} sm={8}>
								<div
									className={
										secondary
											? classes.questionHeaderBox2
											: classes.questionHeaderBox
									}
								>
									<div
										className={
											secondary
												? classes.questionHeader2
												: classes.questionHeader
										}
									>
										What service do you need?
									</div>
									{/* <div className={classes.questionSubHeader}>
										{`This ${proAdjective.singular} offers:`}
									</div> */}
								</div>
							</Grid>
							<Grid item xs={false} sm={2} />
						</Grid>

						<Box
							border={secondary ? 0 : 1}
							borderBottom={0}
							borderColor={"grey.200"}
						>
							<RadioGroup name={"service"}>
								<FormControl component="fieldset" fullWidth>
									{store.affinities.map((service, i) => {
										return (
											<div
												className={
													secondary ? classes.inputBorder2 : classes.inputBorder
												}
												key={`service-choice-${i}`}
											>
												<FormControlLabel
													classes={{
														root: secondary
															? classes.formControl2
															: classes.formControl,
													}}
													control={
														<Radio
															onChange={nextHandler}
															inputRef={serviceChoiceRefs[i]}
															color={"primary"}
															defaultChecked={false}
															id={service._id}
															value={service._id}
														/>
													}
													label={service.name_proper || service.name}
												/>
											</div>
										);
									})}
								</FormControl>
							</RadioGroup>
						</Box>
					</Background>
				</LoadingTemplate>
			</DialogContent>

			<ErrorTemplate />

			<Footer prevHandler={prevHandler} nextHandler={nextHandler} />
		</React.Fragment>
	);
});

export default ChooseService;
