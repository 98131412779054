import React from "react";
import PropTypes from "prop-types";
import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import withStyles from "@framework/src/libs/withStyles";
import { styles } from "@components/Main/Shared/LeadFunnel/.config";

const { BRAND_NAME } = process.env;

const PhoneFooter = withStyles(styles)(
	({ nextHandler, skipHandler, buttonText }) => {
		const classes = useStyles("LeadFunnel");
		const { phoneStore: store } = useStores();

		return (
			<React.Fragment>
				<DialogActions className={classes.dialogActions}>
					<Box
						display={"flex"}
						minWidth={"50%"}
						flexDirection={"row-reverse"}
						className={classes.dialogActionsBox}
					>
						{/* <Box flexGrow={1}>
						{nextHandler && (
							<Button
								disableElevation
								fullWidth
								size={"large"}
								variant={"contained"}
								color="primary"
								onClick={nextHandler}
							>
								Text me
							</Button>
						)}
					</Box> */}
						{skipHandler && (
							<Box flexGrow={1}>
								<Button
									fullWidth
									disableElevation
									size={"large"}
									variant={"contained"}
									color="primary"
									onClick={skipHandler}
								>
									{buttonText ? buttonText : "View request"}
								</Button>
							</Box>
						)}
					</Box>
					{/* <Box fontSize={12} lineHeight={1.5} mt={2} textAlign={"center"}>
					Authorize {BRAND_NAME} to text me about account activity.{" "}
					<Link href="/terms-of-service" target="_blank">
						Terms apply
					</Link>
				</Box> */}
				</DialogActions>
			</React.Fragment>
		);
	}
);

PhoneFooter.propTypes = {
	nextHandler: PropTypes.func,
	skipHandler: PropTypes.func,
};

export default PhoneFooter;
