import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";
import DialogContent from "@material-ui/core/DialogContent";

const Networx = ({ iframeSrc, onError }) => {
	const classes = useStyles("LeadFunnel");
	const { networxStore: store, moreProsStore } = useStores();

	useEffect(() => {
		window.addEventListener("message", function (event) {
			const data = event.data;
			const { source, payload } = data;
			if (source === "networx" && payload.leadDone) {
				moreProsStore.next().catch(onError);
			}
		});

		return () => {};
	}, []);

	return (
		<DialogContent className={classes.dialogContentAngi}>
			<div style={{ height: "100%" }}>
				<iframe
					id="networx-frame"
					src={iframeSrc}
					width="100%"
					height="100%"
					style={{ border: "unset" }}
				></iframe>
			</div>
		</DialogContent>
	);
};

Networx.defaultProps = {};

Networx.propTypes = {
	iframeSrc: PropTypes.string.isRequired,
};
export default Networx;
