import React from "react";
import PropTypes from "prop-types";

import useStyles from "@hooks/useStyles";
import useStores from "@hooks/useStores";

import DialogActions from "@material-ui/core/DialogActions";
// import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Button from "@custom/Button";

const Footer = ({ nextHandler, skipHandler, prevHandler }) => {
	const { leadFunnelStore } = useStores();
	const classes = useStyles("LeadFunnel");

	leadFunnelStore.currentNextHandler = () => {
		const nextButton = document.getElementById("lead_funnel_next_button");
		const skipButton = document.getElementById("lead_funnel_skip_button");
		if (nextButton && !nextButton.disabled) nextButton.click();
		else if (skipButton && !skipButton.disabled) skipButton.click();
	};

	return (
		<React.Fragment>
			<DialogActions className={classes.dialogActions}>
				<Box
					display={"flex"}
					minWidth={"50%"}
					flexDirection={"row-reverse"}
					className={classes.dialogActionsBox}
				>
					<Box flexGrow={1}>
						<Box position={"relative"}>
							{skipHandler ? (
								<Button
									id={"lead_funnel_skip_button"}
									disableElevation
									fullWidth
									size={"large"}
									variant={"contained"}
									color="primary"
									onClick={skipHandler}
									className={`${classes.dialogActionsButton} question-skip`}
								>
									Skip
								</Button>
							) : (
								<Button
									id={"lead_funnel_next_button"}
									disableElevation
									fullWidth
									size={"large"}
									variant={"contained"}
									color="primary"
									onClick={nextHandler}
									className={`${classes.dialogActionsButton} question-next`}
								>
									Next
								</Button>
							)}
						</Box>
					</Box>
					{prevHandler && (
						<Box flexGrow={1} mr={5}>
							<Box position={"relative"}>
								<Button
									id={"lead_funnel_prev_button"}
									fullWidth
									size={"large"}
									variant={"outlined"}
									onClick={prevHandler}
									className={classes.dialogActionsButton}
								>
									Back
								</Button>
							</Box>
						</Box>
					)}
				</Box>
			</DialogActions>
		</React.Fragment>
	);
};

Footer.propTypes = {
	nextHandler: PropTypes.func,
	prevHandler: PropTypes.func,
	skipHandler: PropTypes.func,
};

export default Footer;
