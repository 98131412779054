import React, { useEffect } from "react";
import { observer } from "mobx-react";
import shortid from "shortid";
import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";
import useStores from "@hooks/useStores";

import Background from "../Shared/Background";
import Footer from "../Shared/Footer";
import Header from "../Shared/Header";

import FormControl from "@material-ui/core/FormControl";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

const QuestionFooterProxy = observer(
	({ nextHandler, prevHandler, skipHandler, secondary }) => {
		const { leadFunnelQuestionStore: store } = useStores();

		return (
			<Footer
				nextHandler={nextHandler}
				prevHandler={store.currentStep > 0 ? prevHandler : undefined}
				skipHandler={
					store.question.skip && !store.hasAnswer() ? skipHandler : undefined
				}
			/>
		);
	}
);

const Question = observer(({ secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { leadFunnelQuestionStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();

	const nextHandler = () => {
		try {
			store.next();
			scrollToTop();
		} catch (err) {
			onError(err);
		}
	};
	const scrollToTop = () => {
		const dialogContentElem = document.getElementById("lead-dialog-content");
		dialogContentElem.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};
	const prevHandler = () => store.prev();
	const skipHandler = () => store.skip();

	useEffect(() => {
		if (
			store.question.answers.length === 1 &&
			store.question.answers[0].selected
		) {
			nextHandler();
		}
	}, [store.currentStep]);

	return (
		<React.Fragment>
			<Header progress={store.progress} />

			<DialogContent
				id={"lead-dialog-content"}
				className={secondary ? classes.dialogContent2 : classes.dialogContent}
			>
				<Background nextHandler={nextHandler}>
					<Grid container>
						<Grid item xs={false} sm={1} />
						<Grid item xs={12} sm={10}>
							<div
								className={
									secondary
										? classes.questionHeaderBox2
										: classes.questionHeaderBox
								}
							>
								<div
									className={
										secondary ? classes.questionHeader2 : classes.questionHeader
									}
								>
									{store.question.text}
								</div>

								{store.question.header && (
									<div
										className={
											secondary
												? classes.questionSubHeader2
												: classes.questionSubHeader
										}
									>
										{store.question.header.trim()}
									</div>
								)}
							</div>
						</Grid>
						<Grid item xs={false} sm={1} />
					</Grid>

					<Box
						border={secondary ? 0 : 1}
						borderBottom={0}
						borderColor={"grey.200"}
						className={secondary && classes.widthAuto}
					>
						<FormControl
							component="fieldset"
							fullWidth
							className={secondary && classes.width}
						>
							{store.question.answers.map((answer) => {
								const Component = store.findAnswerComponentByType(answer.type);
								if (!Component) return "";
								return (
									<Component
										nextHandler={nextHandler}
										key={shortid.generate()}
										question={store.question}
										answer={answer}
										secondary={secondary}
									/>
								);
							})}
						</FormControl>
					</Box>
				</Background>
			</DialogContent>

			<ErrorTemplate />

			<QuestionFooterProxy
				nextHandler={nextHandler}
				prevHandler={prevHandler}
				skipHandler={skipHandler}
			/>
		</React.Fragment>
	);
});

export default Question;
