import React, { useState } from "react";
import PropTypes from "prop-types";

// Hooks
import useStyles from "@hooks/useStyles";
import useStores from "@libs/framework/src/hooks/useStores";

// MUI
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

// MUI Icons
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import CancelModal from "./Cancel";

const Header = ({ progress, back, closeHandler }) => {
	const classes = useStyles("LeadFunnel");
	const { leadFunnelModalStore, leadFunnelStore } = useStores();
	const [showCancelModal, setShowCancelModal] = useState();

	const onCloseLead = () => {
		if (closeHandler) return closeHandler();
		if (!leadFunnelStore.Control.progress)
			return leadFunnelModalStore.onClose();
		setShowCancelModal(true);
	};

	return (
		<React.Fragment>
			<DialogTitle disableTypography className={classes.dialogTitleRoot}>
				{back && (
					<React.Fragment>
						<Box
							position={"absolute"}
							display={{ xs: "none", sm: "block" }}
							top={8}
							left={12}
						>
							<Button
								color={"primary"}
								className={classes.button}
								startIcon={<ArrowBackIcon />}
							>
								Back
							</Button>
						</Box>
						<Box
							display={{ xs: "block", sm: "none" }}
							position={"absolute"}
							top={1}
							left={1}
						>
							<IconButton aria-label="close" color={"primary"}>
								<ArrowBackIcon />
							</IconButton>
						</Box>
					</React.Fragment>
				)}

				{progress && (
					<Box mt={1.5}>
						<Grid container>
							<Grid item xs={1} sm={2} />
							<Grid item xs={10} sm={8}>
								<LinearProgress
									variant={"determinate"}
									value={progress}
									classes={{
										root: classes.progressRoot,
										bar: classes.progressBar,
										colorPrimary: classes.progressColorPrimary,
									}}
								/>
							</Grid>
							<Grid item xs={1} sm={2} />
						</Grid>
					</Box>
				)}

				{leadFunnelStore.isModal && (
					<IconButton
						aria-label="close"
						className={classes.closeModal}
						onClick={onCloseLead}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<CancelModal
				show={showCancelModal}
				setShowCancelModal={setShowCancelModal}
			/>
		</React.Fragment>
	);
};

Header.propTypes = {
	progress: PropTypes.number,
	back: PropTypes.bool,
};

export default Header;
