import React, { useRef } from "react";
import { observer } from "mobx-react";
import useStores from "@hooks/useStores";

import shortid from "shortid";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import Rating from "@material-ui/lab/Rating";
import Chip from "@material-ui/core/Chip";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import ListItem from "@material-ui/core/ListItem";
import useStyles from "@hooks/useStyles";

import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import FinalPrice from "@components/Global/Custom/FinalPrice";

const { BRAND_NAME } = process.env;

const LiaisonProCheckbox = observer(({ provider, estimated_price }) => {
	const great_value = provider.great_value;
	const classes = useStyles("LeadFunnel");
	const { liaisonStore: store } = useStores();
	const checkBoxRef = useRef();
	const onChangeHandler = (e) => {
		if (e.target.checked) store.addPick(e.target.id);
		else store.removePick(e.target.id);
	};
	const onClickHandler = () => {
		if (!checkBoxRef.current.checked) store.addPick(checkBoxRef.current.id);
		else store.removePick(checkBoxRef.current.id);
	};

	return (
		<React.Fragment>
			<ListItem
				onClick={onClickHandler}
				button
				className={classes.moreProsListItemBorder}
			>
				<ListItemIcon className={classes.moreProsListItemIcon}>
					<Checkbox
						inputRef={checkBoxRef}
						disableRipple
						color={"primary"}
						edge="start"
						checked={store.isChecked(provider._id)}
						value={provider._id}
						onChange={onChangeHandler}
						id={provider._id}
						tabIndex={-1}
						inputProps={{ "aria-labelledby": "Select pro" }}
					/>
				</ListItemIcon>
				<ListItemText>
					<div>
						<div className={classes.badFitFlex}>
							<Avatar
								variant={"rounded"}
								src={provider.image.value}
								alt={provider.company_name}
								title={provider.company_name}
								className={classes.badFitAvatar}
							/>
							<div className={classes.bizDetailsBox}>
								<div className={classes.bizDetailsName}>
									{provider.company_name.ucFirst()}
								</div>
								<div className={classes.bizDetailsRatingBox}>
									{provider.metrics.reviews_count > 0 ? (
										<>
											<span className={classes.bizDetailsRating}>
												{provider.metrics.reviews_avg.toFixed(1)}
											</span>
											<Rating
												name="half-rating-read"
												value={parseFloat(
													provider.metrics.reviews_avg.toFixed(1)
												)}
												precision={0.5}
												readOnly
												size="small"
											/>
											<span className={classes.bizDetailsRatingCount}>
												({provider.metrics.reviews_count})
											</span>
										</>
									) : (
										<>
											<Rating max={1} value={1} readOnly size="small" />

											<span className={classes.bizDetailsRatingCount}>
												{`New on ${BRAND_NAME}`}
											</span>
										</>
									)}
								</div>
								{great_value && (
									<div className={classes.bizDetailsChips}>
										<Chip
											classes={{
												root: classes.cardChipRoot,
												icon: classes.cardChipIcon,
											}}
											size="small"
											icon={<AttachMoneyIcon />}
											label="Great value"
										/>
									</div>
								)}

								{provider.metrics.hires !== 0 && (
									<div className={`${classes.proFacts6}`}>
										<span className={classes.proFactsBox}>
											<TrendingUpIcon
												fontSize="small"
												className={classes.proFactsIcon}
											/>
											<span className={classes.proFactsText}>
												<span className={classes.proFactsTextB}>
													{`${provider.metrics.hires} ${
														provider.metrics.hires === 1 ? "hire" : "hires"
													}`}
												</span>
												{` on ${BRAND_NAME}`}
											</span>
										</span>
									</div>
								)}
								{/* {provider.location && (
									<div className={`${classes.proFacts6}`}>
										<span className={classes.proFactsBox}>
											<LocationOnOutlinedIcon
												fontSize="small"
												className={classes.proFactsIcon}
											/>
											<span className={classes.proFactsText}>
												{`Serves ${provider.location.city.ucFirst()}, ${provider.location.state.toUpperCase()}`}
											</span>
										</span>
									</div>
								)} */}
								<div className={classes.bizDetailsPriceMobile}>
									<div className={classes.flexCenter}>
										{store.displayChatBubble(estimated_price?.total) && (
											<ChatBubbleOutlineOutlinedIcon
												className={classes.bizDetailsPriceIcon}
											/>
										)}
										{!!estimated_price?.total && (
											<span className={classes.bizDetailsPriceAmount}>
												{`$${estimated_price?.total}`}
											</span>
										)}
										{store.displayUnitSpan(estimated_price?.unit) && (
											<span className={classes.bizDetailsPriceType}>
												{estimated_price?.unit}
											</span>
										)}
									</div>
									{estimated_price?.description && (
										<div className={classes.bizDetailsPriceDesc}>
											{estimated_price?.description}
										</div>
									)}
								</div>
							</div>
							{/* <div className={classes.bizDetailsPrice}>
								<div className={classes.flexCenter}>
									{store.displayChatBubble(estimated_price?.unit) && (
										<ChatBubbleOutlineOutlinedIcon
											className={classes.bizDetailsPriceIcon}
										/>
									)}
									{!!estimated_price?.total && (
										<span className={classes.bizDetailsPriceAmount}>
											{`$${estimated_price?.total}`}
										</span>
									)}
									{store.displayUnitSpan(estimated_price?.unit) && (
										<span className={classes.bizDetailsPriceType}>
											{estimated_price?.unit}
										</span>
									)}
								</div>
								{store.subHeader(estimated_price?.unit) && (
									<div className={classes.bizDetailsPriceDesc}>
										{store.subHeader(estimated_price?.unit).toLowerCase()}
									</div>
								)}
							</div> */}
							<div className={classes.bizDetailsPrice}>
								<FinalPrice
									total={estimated_price?.total}
									unit={estimated_price?.unit}
									description={estimated_price?.description}
								/>
							</div>
						</div>
					</div>
				</ListItemText>
			</ListItem>
		</React.Fragment>
	);
});

export default LiaisonProCheckbox;
