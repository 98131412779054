import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";

import useError from "@hooks/useError";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import useLoading from "@hooks/useLoading";

import Background from "../Shared/Background";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";

import { PasswordTextField } from "@components/Global/Custom/TextField";

const Password = observer(({ secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { passwordStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();
	const [LoadingTemplate, onLoading] = useLoading({
		_id: "LeadFunnelPassword",
	});

	const passwordRef = useRef();

	const nextHandler = () => store.next(passwordRef).catch(onError);
	const prevHandler = () => store.prev();
	const onChangeHandler = (e) => store.update(e.target.value);

	useEffect(() => {
		store
			.init()
			.then(() => onLoading(false))
			.catch(onError);
		return () => onLoading(true);
	}, []);

	return (
		<>
			<Header progress={store.progress} />
			<DialogContent className={classes.dialogContent}>
				<Background nextHandler={nextHandler}>
					<LoadingTemplate>
						<Grid container>
							<Grid item xs={false} sm={2} />
							<Grid item xs={12} sm={8}>
								<div className={classes.questionHeaderBox}>
									<div className={classes.questionHeader}>
										Please enter your password.
									</div>
								</div>
								<div>
									<PasswordTextField
										inputRef={passwordRef}
										id="password"
										value={store.password || ""}
										placeholder="Password"
										onChange={onChangeHandler}
										variant={"outlined"}
										fullWidth={true}
									/>
								</div>
							</Grid>
							<Grid item xs={false} sm={2} />
						</Grid>
					</LoadingTemplate>
				</Background>
			</DialogContent>
			<ErrorTemplate />
			<Footer nextHandler={nextHandler} prevHandler={prevHandler} />
		</>
	);
});

export default Password;
