import React from "react";
import useStores from "@hooks/useStores";

import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";

import DialogContent from "@material-ui/core/DialogContent";

import Button from "@custom/Button";
import Dialog from "@material-ui/core/Dialog";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import useStyles from "@hooks/useStyles";

import { adjective as proAdjective } from "@language/provider";
import shortid from "shortid";

const AreYouSureContent = ({}) => {
	const classes = useStyles("LeadFunnel");
	const { moreProsStore: store } = useStores();
	const pickMoreProsHandler = () => store.setShowAreYouSure(false);
	const doneHandler = () => store.next();

	return (
		<React.Fragment>
			<Dialog
				fullWidth={true}
				maxWidth={"xs"}
				open={true}
				//onClose={leadFunnelModalStore.onClose}
				aria-labelledby="You only selected one pro."
				aria-describedby="Do you want to continue?"
			>
				<DialogTitle disableTypography>
					{store.ogProvider && (
						<Box pt={3}>
							<AvatarGroup max={3} classes={{ avatar: classes.avatarGroup }}>
								<Avatar
									key={shortid.generate()}
									className={classes.selectMoreAvatar}
									alt={store.ogProvider.company_name}
									src={store.ogProvider.image?.value}
								/>
							</AvatarGroup>
						</Box>
					)}
				</DialogTitle>
				<DialogContent>
					<Box mb={6}>
						<Box
							fontSize={{ xs: 20, sm: 24 }}
							fontWeight={700}
							mb={3}
							lineHeight={1.3}
						>
							{store.ogProvider ? (
								<>{`You only selected 1 ${proAdjective.singular}.`}</>
							) : (
								<>{`Are you sure? You'll get less bids.`}</>
							)}
						</Box>

						<Box component={"div"} lineHeight={1.6}>
							{`We recommend contacting 2 to 3 ${proAdjective.plural} so that you can compare prices and availability.`}
						</Box>
					</Box>

					<Box mb={5}>
						<Box mb={3}>
							<Button
								onClick={pickMoreProsHandler}
								disableElevation
								size={"large"}
								variant={"contained"}
								color="primary"
								fullWidth
							>
								{store.ogProvider ? (
									<>{`Add more ${proAdjective.plural}`}</>
								) : (
									<>{`Add more ${proAdjective.plural}`}</>
								)}
							</Button>
						</Box>
						<div>
							<Button
								onClick={doneHandler}
								size={"large"}
								variant={"outlined"}
								fullWidth
							>
								{store.ogProvider ? (
									<>{`Continue with 1 ${proAdjective.singular}`}</>
								) : (
									<>{`Continue without selecting`}</>
								)}
							</Button>
						</div>
					</Box>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
};

export default AreYouSureContent;
