import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { observer } from "mobx-react";
import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";
import DialogContent from "@material-ui/core/DialogContent";

const { ENV } = process.env;

const Angi = ({ angiLeadKey, onError }) => {
	const classes = useStyles("LeadFunnel");
	const { angiStore: store, moreProsStore } = useStores();

	useEffect(() => {
		const isTest = !ENV.startsWith("prod");
		console.log("Starting Angi DropIn", angiLeadKey, isTest);
		window.initializeDropIn(angiLeadKey, isTest);
		window.addEventListener("message", function (event) {
			console.log("event", event);
			if (
				event.data &&
				event.data.event === "pageState" &&
				event.data.payload === "ThankYou"
			) {
				moreProsStore.next().catch(onError);
			}
		});
		return () => {};
	}, []);

	return (
		<DialogContent className={classes.dialogContentAngi}>
			<div class="dropin-container" style={{ height: "100%" }}></div>
		</DialogContent>
	);
};

Angi.defaultProps = {};

Angi.propTypes = {
	angiLeadKey: PropTypes.string.isRequired,
};
export default Angi;
