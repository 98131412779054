import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { compose } from "recompose";

import withStores from "@framework/src/libs/withStores";
import withStyles from "@framework/src/libs/withStyles";

import useStores from "@hooks/useStores";
import useError from "@hooks/useError";

import { stores, styles } from "./.config";

import * as Steps from "./Steps";

const { SITE_NAME } = process.env;
const { getParameterByName } = require("@utils/Browser");

function initializeTrustedForm() {
	if (SITE_NAME !== "homeguide") return;
	var field = "xxTrustedFormCertUrl";
	var provideReferrer = false;
	var invertFieldSensitivity = false;
	var tf = document.createElement("script");
	tf.type = "text/javascript";
	tf.async = true;
	tf.src =
		"http" +
		("https:" == document.location.protocol ? "s" : "") +
		"://api.trustedform.com/trustedform.js?provide_referrer=" +
		escape(provideReferrer) +
		"&field=" +
		escape(field) +
		"&l=" +
		new Date().getTime() +
		Math.random() +
		"&invert_field_sensitivity=" +
		invertFieldSensitivity;
	var s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(tf, s);
}

function initializeAngi() {
	if (SITE_NAME !== "homeguide") return;
	var angi = document.createElement("script");
	angi.type = "text/javascript";
	angi.async = true;
	angi.src =
		"http" +
		("https:" == document.location.protocol ? "s" : "") +
		"://request.angi.com/hcc/dropin-112a615a05e36545.js";
	var s = document.getElementsByTagName("script")[0];
	s.parentNode.insertBefore(angi, s);
}

const LeadFunnel = compose(
	withStyles(styles),
	withStores(stores),
	observer
)(
	({
		provider,
		zipcode,
		service,
		base64EncodedAnswerKeys,
		isModal,
		secondary,
	}) => {
		const [ErrorTemplate, onError] = useError();
		const { leadFunnelStore } = useStores();

		useEffect(() => {
			if (isModal) return;
			const leadActionType =
				SITE_NAME === "homeguide" ? "request_quote" : "request_availability";
			if (getParameterByName("showZip") === "1") {
				const zipIndex = leadFunnelStore.Control.steps.findIndex(
					(step) => step === "Zipcode"
				);
				leadFunnelStore.Control.steps.splice(zipIndex, 1);
				leadFunnelStore.Control.steps.splice(1, 0, "Zipcode");
			}
			leadFunnelStore.Control.currentStep = 1;
			leadFunnelStore
				.init({
					provider,
					zipcode,
					isModal: false,
					leadActionType,
					service,
					Steps,
					base64EncodedAnswerKeys,
				})
				.then()
				.catch(onError);
			const onKeyPress = (e) => {
				if (e.key === "Enter") {
					e.preventDefault();
					leadFunnelStore?.currentNextHandler();
				}
			};
			document.removeEventListener("keydown", onKeyPress);
			document.addEventListener("keydown", onKeyPress, false);
		}, []);

		useEffect(() => {
			initializeTrustedForm();
			initializeAngi();
		}, []);

		return (
			<React.Fragment>
				{SITE_NAME === "homeguide" && (
					<>
						<noscript>
							<img src="http://api.trustedform.com/ns.gif" />
						</noscript>
					</>
				)}
				{<form></form> /*keep this for trustedform*/}
				{leadFunnelStore.initialized && (
					<leadFunnelStore.Control.Component
						provider={provider}
						service={service}
						secondary={secondary}
					/>
				)}
				<ErrorTemplate />
			</React.Fragment>
		);
	}
);

LeadFunnel.propTypes = {
	provider: PropTypes.object,
	zipcode: PropTypes.string,
	service: PropTypes.object,
	isModal: PropTypes.bool,
	secondary: PropTypes.bool,
};

export default LeadFunnel;
