import React, { useEffect } from "react";
import { observer } from "mobx-react";

import useError from "@hooks/useError";
import useStores from "@hooks/useStores";

import AreYouSureContent from "./AreYouSureContent";

import Header from "@components/Main/Shared/LeadFunnel/Steps/Shared/Header";
import useStyles from "@hooks/useStyles";

import Angi from "./partners/Angi/Angi";
import Networx from "./partners/Networx";
import Liaison from "./partners/Liaison/Liaison";

const MorePros = observer(({ provider, secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { moreProsStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();

	const notNowHandler = () => store.setShowAreYouSure(true);

	useEffect(() => {}, []);

	return (
		<React.Fragment>
			{store.showAreYouSure && <AreYouSureContent />}

			<Header closeHandler={notNowHandler} />

			{store.partner === "ha" && (
				<Angi angiLeadKey={store.angiLeadKey} onError={onError} />
			)}
			{store.partner === "liaison" && <Liaison onError={onError} />}
			{store.partner === "networx" && (
				<Networx iframeSrc={store.networxUrl} onError={onError} />
			)}

			<ErrorTemplate />
		</React.Fragment>
	);
});

export default MorePros;
